/* 
Table Of Contents
=========================
- Default Typography
- Custom Typography
=========================
*/


/* 
--------------------------
- Default Typography
--------------------------
*/

body {
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #000;
    letter-spacing: 1.5px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
    font-family: 'Poppins', sans-serif;
}

h1 {
    font-size: 36px;
    line-height: 70px;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

h2 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 0px;
}

h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0px;
}

h4 {
    font-size: 20px;
    line-height: 27px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 14px;
    line-height: 24px;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
}

p img {
    margin: 0;
}

span {
    font-family: 'Poppins', sans-serif;
}



/* em and strong */

em {
    font: 15px/30px;
}

strong,
b {
    font: 15px/30px;
}

small {
    font-size: 11px;
    line-height: inherit;
}



/*  blockquotes */

blockquote {
    border-left: 0 none;
    font-family: 'Poppins', sans-serif;
    margin: 10px 0;
    padding-left: 40px;
    position: relative;
}

blockquote::before {
    color: #4e5665;
    content: "";
    font-family: FontAwesome;
    font-size: 36px;
    left: 20px;
    line-height: 0;
    margin: 0;
    opacity: 0.5;
    position: absolute;
    top: 20px;
}

blockquote p {
    font-style: italic;
    padding: 0;
    font-size: 18px;
    line-height: 36px;
}

blockquote cite {
    display: block;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
}

blockquote cite:before {
    content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
    color: #8B9798;
    border: none
}



/* abbreviations */

abbr {
    color: #444;
    font-weight: 700;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: .6px;
}

abbr:hover {
    cursor: help;
}



/* links */

a,
a:visited {
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    color: #111;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: 0;
}

a:hover,
a:active {
    color: #5b1ffa;
    text-decoration: none;
    outline: 0;
}

a:focus {
    text-decoration: none;
    outline: 0;
}

p a,
p a:visited {
    line-height: inherit;
    outline: 0;
}

a.active-page {
    color: #e6ae48!important;
}



/* list */

ul,
ol {
    margin-bottom: 0px;
    margin-top: 0px;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ol {
    list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
    margin-left: 0px;
}

ul.square {
    list-style: square outside;
}

ul.circle {
    list-style: circle outside;
}

ul.disc {
    list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
    margin-bottom: 0px;
}

li {
    line-height: 18px;
    margin-bottom: 0px;
}

ul.large li {}

li p {}



/* definition list */

dl {
    margin: 12px 0;
}

dt {
    margin: 0;
    color: #11ABB0;
}

dd {
    margin: 0 0 0 20px;
}

button {}



/* 
--------------------------
- Custom Typography
--------------------------
*/


/* Your Custom Typography. */


/*pealoader css*/

div#preloader {
    height: 100%;
    left: 0;
    overflow: visible;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    background: #fff;
}


.spinner {
    width: 40px;
    height: 40px;
    margin: auto;
    background-color: #333;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}


/*----------------------------
 Basic margin padding
-----------------------------*/

.m--0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.p--0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}


/*---------------------------
   Margin top
--------------------------*/

.mt--0 {
    margin-top: 0
}

.mt--10 {
    margin-top: 10px
}

.mt--15 {
    margin-top: 15px
}

.mt--20 {
    margin-top: 20px
}

.mt--30 {
    margin-top: 30px
}

.mt--40 {
    margin-top: 40px
}

.mt--50 {
    margin-top: 50px
}

.mt--60 {
    margin-top: 60px
}

.mt--70 {
    margin-top: 70px
}

.mt--80 {
    margin-top: 80px
}

.mt--90 {
    margin-top: 90px
}

.mt--100 {
    margin-top: 100px
}

.mt--110 {
    margin-top: 110px
}

.mt--120 {
    margin-top: 120px
}

.mt--130 {
    margin-top: 130px
}

.mt--140 {
    margin-top: 140px
}

.mt--150 {
    margin-top: 150px
}


/*-----------------------------
  Margin bottom
-------------------------------*/

.mb--0 {
    margin-bottom: 0
}

.mb--10 {
    margin-bottom: 10px
}

.mb--15 {
    margin-bottom: 15px
}

.mb--20 {
    margin-bottom: 20px
}

.mb--30 {
    margin-bottom: 30px
}

.mb--40 {
    margin-bottom: 40px
}

.mb--50 {
    margin-bottom: 50px
}

.mb--60 {
    margin-bottom: 60px
}

.mb--70 {
    margin-bottom: 70px
}

.mb--80 {
    margin-bottom: 80px
}

.mb--90 {
    margin-bottom: 90px
}

.mb--100 {
    margin-bottom: 100px
}

.mb--110 {
    margin-bottom: 110px
}

.mb--120 {
    margin-bottom: 120px
}

.mb--130 {
    margin-bottom: 130px
}

.mb--140 {
    margin-bottom: 140px
}

.mb--150 {
    margin-bottom: 150px
}


/*--------------------------
  Padding top
---------------------------*/

.pt--0 {
    padding-top: 0
}

.pt--10 {
    padding-top: 10px
}

.pt--15 {
    padding-top: 15px
}

.pt--20 {
    padding-top: 20px
}

.pt--30 {
    padding-top: 30px
}

.pt--40 {
    padding-top: 40px
}

.pt--50 {
    padding-top: 50px
}

.pt--60 {
    padding-top: 60px
}

.pt--70 {
    padding-top: 70px
}

.pt--80 {
    padding-top: 80px
}

.pt--90 {
    padding-top: 90px
}

.pt--100 {
    padding-top: 100px
}

.pt--110 {
    padding-top: 110px
}

.pt--120 {
    padding-top: 120px
}

.pt--130 {
    padding-top: 130px
}

- .pt--140 {
    padding-top: 140px
}

.pt--150 {
    padding-top: 150px
}


/*------------------------
   Padding bottom
---------------------------*/

.pb--0 {
    padding-bottom: 0
}

.pb--10 {
    padding-bottom: 10px
}

.pb--15 {
    padding-bottom: 15px
}

.pb--20 {
    padding-bottom: 20px
}

.pb--30 {
    padding-bottom: 30px
}

.pb--40 {
    padding-bottom: 40px
}

.pb--50 {
    padding-bottom: 50px
}

.pb--60 {
    padding-bottom: 60px
}

.pb--70 {
    padding-bottom: 70px
}

.pb--80 {
    padding-bottom: 80px
}

.pb--90 {
    padding-bottom: 90px
}

.pb--100 {
    padding-bottom: 100px
}

.pb--110 {
    padding-bottom: 110px
}

.pb--120 {
    padding-bottom: 120px
}

.pb--130 {
    padding-bottom: 130px
}

.pb--140 {
    padding-bottom: 140px
}

.pb--150 {
    padding-bottom: 150px
}

.pb--160 {
    padding-bottom: 160px
}

.pb--170 {
    padding-bottom: 170px
}

.pb--180 {
    padding-bottom: 180px
}

.pb--190 {
    padding-bottom: 190px
}



/*------------------------------
   Page section padding 
-------------------------------*/

.ptb--0 {
    padding: 0
}

.ptb--10 {
    padding: 10px 0
}

.ptb--20 {
    padding: 20px 0
}

.ptb--30 {
    padding: 30px 0
}

.ptb--40 {
    padding: 40px 0
}

.ptb--50 {
    padding: 50px 0
}

.ptb--60 {
    padding: 60px 0
}

.ptb--70 {
    padding: 70px 0
}

.ptb--80 {
    padding: 80px 0
}

.ptb--90 {
    padding: 90px 0
}

.ptb--100 {
    padding: 100px 0
}

.ptb--110 {
    padding: 110px 0
}

.ptb--120 {
    padding: 120px 0
}

.ptb--130 {
    padding: 130px 0
}

.ptb--140 {
    padding: 140px 0
}

.ptb--150 {
    padding: 150px 0
}
.ptb--160 {
    padding: 160px 0
}
.ptb--170 {
    padding: 170px 0
}
.ptb--180 {
    padding: 180px 0
}


/*---------------------------
   Page section margin 
-----------------------------*/

.mtb--0 {
    margin: 0
}

.mtb--10 {
    margin: 10px 0
}

.mtb--15 {
    margin: 15px 0
}

.mtb--20 {
    margin: 20px 0
}

.mtb--30 {
    margin: 30px 0
}

.mtb--40 {
    margin: 40px 0
}

.mtb--50 {
    margin: 50px 0
}

.mtb--60 {
    margin: 60px 0
}

.mtb--70 {
    margin: 70px 0
}

.mtb--80 {
    margin: 80px 0
}

.mtb--90 {
    margin: 90px 0
}

.mtb--100 {
    margin: 100px 0
}

.mtb--110 {
    margin: 110px 0
}

.mtb--120 {
    margin: 120px 0
}

.mtb--130 {
    margin: 130px 0
}

.mtb--140 {
    margin: 140px 0
}

.mtb--150 {
    margin: 150px 0;
}

.d-flex{
    display: flex;
}
.flex-center{
    align-items: center;
}
.h-100{
    height: 100%;
}
.meta-content{
    overflow: hidden;
}
.bg-gray{
    background-color: #fcfcfc;
}